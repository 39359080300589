import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Row, Col, Card, Alert, Button, OverlayTrigger, Dropdown, Tooltip} from 'react-bootstrap';
import BootstrapTable from '../../../Misc/BootstrapTable.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import UserHelper from '../../../Helper/UserHelper.js';
import ApiCall from '../../../Helper/ApiCall.js';
import Pagination from '../../../Pagination/Pagination.js';
import PaginationInfo from '../../../Pagination/PaginationInfo.js';
import OrderSettingsModal from './OrderSettingsModal.js';
import {withProgressBarContext} from '../../../Provider/ProgressBarProvider.js';

import './AccountOrderHistory.scss';

class AccountOrderHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ordersPage: {},
            showOrderSettingsModal: false,
            isLoading: true
        };

        this.getOrders = this.getOrders.bind(this);
        this.getActionsFormatForOrder = this.getActionsFormatForOrder.bind(this);
        this.deleteOrder = this.deleteOrder.bind(this);
        this.getOrderSettings = this.getOrderSettings.bind(this);
        this.downloadInvoice = this.downloadInvoice.bind(this);
        this.getCompanyDetails = this.getCompanyDetails.bind(this);
        this.getTrColor = this.getTrColor.bind(this);
        this.handleOrderSettingsModalClose = this.handleOrderSettingsModalClose.bind(this);
        this.handleOrderSettingsModalShow = this.handleOrderSettingsModalShow.bind(this);
    }

    componentDidMount() {
        this.getOrders(0);
        this.getCompanyDetails();
        this.getOrderSettings();
    }

    render() {
        const expandRow = {
          renderer: row => {
            let entries = row.cart.entries.map(function(entry, key) {
                return <div key={entry.code} id={entry.entity.code}>
                        <span className="ms-5">{entry.entity.name}</span>
                        <span className="float-end me-5">
                            {entry.quantity} {entry.unit.code} x {entry.baseAmount.formatted} = <span className="fw-bold">{entry.totalAmount.formatted}</span>
                        </span>
                 </div>;
            });

            return (
            <div>
                {entries}
                {row.rejectReason ?
                    <Alert variant="danger" className="mt-3">
                        <div className="text-center">
                            <FontAwesomeIcon className="me-1" size="lg" icon="circle-exclamation" />{row.rejectReason}
                        </div>
                    </Alert>
                :""}
            </div>
          )},
          showExpandColumn: true,
          expandByColumnOnly: true,
          expandHeaderColumnRenderer: (isAnyExpands) => {
              if (isAnyExpands) {
                return <b>[-]</b>;
              }
              return <b>[+]</b>;
          },
          expandColumnRenderer: (expanded) => {
              if (expanded) {
                return (
                  <b>[-]</b>
                );
              }
              return (
                <b>[+]</b>
              );
          }
        };

        const columns = [
            {
              dataField: 'code',
              text: 'Order number',
              formatter: (cell, row) => <a href={"/my-account/order-view#" + cell} className="text-dark">{ cell }</a>,
              footer: columnData => <PaginationInfo page={this.state.ordersPage} />,
              footerAttrs: {
                  colSpan: 2
              }
            },
            {
              dataField: 'created',
              text: 'Date Placed'
            },
            {
              dataField: 'status',
              text: 'Order Status'
            },
            {
              dataField: 'totalAmount.formatted',
              text: 'Total'
            },
            {
               dataField: 'dummy2',
               formatter: this.getActionsFormatForOrder,
               text: 'Actions',
               footer: columnData => <Pagination page={this.state.ordersPage} handleChangePage={this.getOrders} isLoading={this.state.isLoading} />,
               footerAttrs: {
                  colSpan: 3
               }
            }
        ];

        let skin = UserHelper.isDark() ? "table-dark" : "";
        return (
            <Row className="mt-3">
                <Col xs={12}>
                    <Card>
                        <Card.Body className="order-history-wrapper">
                            <Card.Title className="text-uppercase fw-bold">
                                Order history
                                {!this.state.companyDetailsValid || !this.state.bankDetailsValid || !(this.state.orderSettings && this.state.orderSettings.orderPrefix) ?
                                    <OverlayTrigger
                                       placement="bottom"
                                       delay={{ show: 250, hide: 400 }}
                                       overlay={<Tooltip id="button-tooltip-2">You need to fill-in your company details and your bank details in the 'Legal' tab
                                        before issuing orders. You also need to specify order prefix in the settings modal box.</Tooltip>}>
                                        <FontAwesomeIcon icon="question" className="ms-2 mt-2 float-end"/>
                                    </OverlayTrigger>
                                :null}
                                <a className="btn btn-secondary float-end text-uppercase ms-2" href='/my-account/order/new' role="button"
                                disabled={this.state.companyDetailsValid && this.state.bankDetailsValid && this.state.orderSettings && this.state.orderSettings.orderPrefix ? '' : 'disabled'}>Create
                                Order</a>
                                <Button className="btn-primary float-end" onClick={() => this.handleOrderSettingsModalShow()}><FontAwesomeIcon
                                icon={"gear"}/></Button>
                                <OrderSettingsModal show={this.state.showOrderSettingsModal} handleOnOrderSettingsModalClose={this
                                .handleOrderSettingsModalClose} orderSettings={this.state.orderSettings}/>
                            </Card.Title>
                            <p>This is a list of your previous orders</p>
                            {this.state.ordersPage ?
                                <BootstrapTable
                                  keyField='code'
                                  classes={"table-align-middle " + skin}
                                  data={ this.state.ordersPage.content }
                                  noDataIndication="No Incoming Orders"
                                  columns={ columns }
                                  expandRow={ expandRow }
                                  bordered={true}
                                  hover
                                  condensed
                                  rowClasses={ this.getTrColor }
                                  isLoading={this.state.isLoading}
                                />
                            :""}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )
    }

    getActionsFormatForOrder(cell, order) {
        const config = {
          modifiers: [
            {
              name: 'computeStyles',
              options: {
                gpuAcceleration: false, // true by default
              },
            },
          ],
        };
        return (
            <Dropdown className="float-end ms-2 text-bf">
                <Dropdown.Toggle variant="transparent" className="text-dark">
                    <FontAwesomeIcon size="lg" icon={["fa", "ellipsis-vertical"]}/>
                </Dropdown.Toggle>
                <Dropdown.Menu popperConfig={config}>
                    <Dropdown.Item onClick={() => {this.props.history.push('/my-account/order#' + order.code)}}>Edit</Dropdown.Item>
                    <Dropdown.Item onClick={() => {this.downloadInvoice(order.code)}}>Get PDF</Dropdown.Item>
                    <Dropdown.Item onClick={() => {this.props.history.push('/my-account/order-view#' + order.code)}}>View</Dropdown.Item>
                    <Dropdown.Item onClick={() => {this.deleteOrder(order.code)}}>Delete</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        );
    }

    getOrders(pageNumber) {
        ApiCall.get('supremeOrder/own/history', {
            page: pageNumber,
            size: 25
        }).then((response) => {
            this.setState({
                ordersPage: response.data,
                isLoading: false
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 30});
        }).catch(error => {
            this.setState({
                ordersPage: null,
                isLoading: false
            });
        });
    }

    getCompanyDetails() {
        let url = "organization/forCurrentUser";
        let projection =  "io.nemesis.platform.facade.location.dto.OrganizationDto";
        ApiCall.get(url, {projection:projection}).then((response) => {
            let organization = response.data;
            this.setState({
                companyDetailsValid : organization.legalName && organization.vatCode && organization.contactPoint && organization.contactPoint.line1 && organization.contactPoint.town && organization.contactPoint.postalcode
                && organization.contactPoint.country && organization.contactPoint.country.code,
                bankDetailsValid: organization.bank && organization.bank.code && organization.bank.name && organization.bank.iban && organization.bank.swift
            });
            this.props.progressBarContext.dispatch({type: 'increment', progress: 30});
        }).catch(error => {
           UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.');
           this.setState({
                profileInfoMsg: null
           });
        });
    }

    downloadInvoice = (orderCode) => {
        this.props.progressBarContext.dispatch({type: 'update', progress: 0});
        fetch(process.env.REACT_APP_FACADE_URL + 'invoice/' + orderCode, {
            method: "GET",
            headers: {
              Accept: "application/pdf",
              "Content-Type": "application/pdf",
              "X-Nemesis-Token":localStorage.getItem("token")
            }
        }).then(response => {
            if (response.status === 200) {
                response.blob().then(blob => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = 'SupremeContractors-Invoice-' + orderCode + '.pdf';
                    a.click();
                });
                this.setState({downloadStarted:false});
                this.props.progressBarContext.dispatch({type: 'update', progress: 100});
            } else {
                UserHelper.showErrorToast("Error: " + response.status);
            }
        });
    }

    deleteOrder(code) {
        this.props.progressBarContext.dispatch({type: 'update', progress: 20});
        ApiCall.delete('supremeOrder/' + code).then((response) => {
            UserHelper.showSuccessToast("The order was deleted.");
            this.getOrders(this.state.ordersPage.number);
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred. Please try again later.', true);
            this.setState({
                profileInfoMsg: null
            });
        });
    }

    getOrderSettings() {
        ApiCall.get("supremeOrder/settings").then((response) => {
            this.setState({orderSettings : response.data});
            this.props.progressBarContext.dispatch({type: 'update', progress: 100});
        }).catch(error => {
            UserHelper.showErrorToast(error && error.message ? error.message : 'An error occurred getting order settings. Please try again later.');
        });
    }


    handleOrderSettingsModalClose() {
        this.setState({
            showOrderSettingsModal: false
        });
        this.getOrderSettings();
    }

    handleOrderSettingsModalShow() {
        this.setState({
            showOrderSettingsModal: true
        });
    }

    getTrColor(order, rowIndex) {
        let result = order.frozen ? "disabled " : "";
        if (order.status === 'CREATED') {
            return result += "table-primary";
        } else if (order.status === 'REJECTED') {
            return result + 'table-danger';
        } else if (order.status === 'ACCEPTED') {
            return result + "table-info";
        } else if (order.status === 'EMAIL_SENT') {
            return result + "table-warning";
        } else if (order.status === 'PAID') {
            return result + "table-success";
        }
    }
}

export default withRouter(withProgressBarContext(AccountOrderHistory));
